export default [
    {
        path: 'disciplina',
        name: 'DisciplinaList',
        meta: { title: "Disciplinas" , permissions: ['disciplina_visualizar']},
        component: () => import('@pages/Disciplina/Disciplina')
    },
    {
        path: 'disciplina/create',
        name: 'DisciplinaCreate',
        meta: { title: "Cadastro de Disciplina" , permissions: ['disciplina_inserir']},
        component: () => import('@pages/Disciplina/DisciplinaCreate')
    },
    {
        path: 'disciplina/edit/:id',
        props: true,
        name: 'DisciplinaEdit',
        meta: { title: "Edição de Disciplina", permissions: ['disciplina_editar'] },
        component: () => import('@pages/Disciplina/DisciplinaEdit')
    },

    {
        path: 'adicionar-justificativa/:registro_aula_id',
        props: true,
        name: 'AdicionarJustificativaAdm',
        meta: {title: "Adicionar Justificativa"},
        component: () => import('@pages/RegistroAula/AdicionarJustificativa')
    },
    {
        path: 'registroaula',
        name: 'RegistroAulaList',
        meta: { title: "Registros de Aula", permissions: ['registro_visualizar'] },
        component: () => import('@pages/RegistroAula/RegistroAulaList')
    },
    {
        path: 'registroaula/create',
        props: true,
        name: 'RegistroAulaCreate',
        meta: { title: "Cadastro de Registro" , permissions: ['registro_inserir']},
        component: () => import('@pages/RegistroAula/RegistroAulaCreate')
    },
    {
        path: 'registroaula/edit/:id',
        props: true,
        name: 'RegistroAulaEdit',
        meta: { title: "Edição de Registro de Aulas", permissions: ['registro_editar'] },
        component: () => import('@pages/RegistroAula/RegistroAulaEdit')
    },
    {
        path: 'matricula',
        name: 'MatriculaList',
        meta: { title: "Matrículas", permissions: ['matricula_visualizar'] },
        component: () => import('@pages/Matricula/Matricula')
    },
    {
        path: 'matricula/create',
        props: true,
        name: 'MatriculaCreate',
        meta: { title: "Cadastro de Matrícula" , permissions: ['matricula_inserir']},
        component: () => import('@pages/Matricula/MatriculaCreate')
    },
    {
        path: 'matricula/edit/:id',
        props: true,
        name: 'MatriculaEdit',
        meta: { title: "Edição de Matrícula", permissions: ['matricula_editar'] },
        component: () => import('@pages/Matricula/MatriculaEdit')
    },
    {
        path: 'matricula/show/:id',
        props: true,
        name: 'MatriculaShow',
        meta: { title: "Ficha de Matrícula" , permissions: ['matricula_visualizar']},
        component: () => import('@pages/Matricula/MatriculaShow')
    },
    {
        path: 'matricula/livro/:id',
        props: true,
        name: 'MatriculaLivroSolicitado',
        meta: { title: "Livros Solicitados", permissions: ['matricula_visualizar'] },
        component: () => import('@pages/Matricula/LivroSolicitado')
    },
    {
        path: 'matricula/material/:id',
        props: true,
        name: 'MatriculaMaterialSolicitado',
        meta: { title: "Materiais Solicitados", permissions: ['matricula_visualizar'] },
        component: () => import('@pages/Matricula/MaterialSolicitado')
    },
    {
        path: 'matricula/online',
        name: 'Configuracao',
        meta: { title: "Matrícula Online", permissions: ['matricula_editar'] },
        component: () => import('@pages/MatriculaOnline/Configuracao')
    },
    {
        path: 'atividade-extracurricular',
        name: 'AtividadeExtracurricularList',
        meta: { title: "Atividades Extracurricularses" },
        component: () => import('@pages/AtividadeExtracurricular/AtividadeExtracurricular')
    },
    {
        path: 'atividade-extracurricular/create',
        name: 'AtividadeExtracurricularCreate',
        meta: { title: "Cadastro de Atividades Extracurriculares" },
        component: () => import('@pages/AtividadeExtracurricular/AtividadeExtracurricularCreate')
    },
    {
        path: 'atividade-extracurricular/edit/:id',
        props: true,
        name: 'AtividadeExtracurricularEdit',
        meta: { title: "Edição de Atividades Extracurriculares" },
        component: () => import('@pages/AtividadeExtracurricular/AtividadeExtracurricularEdit')
    },
    {
        path: 'grupo-conceito',
        name: 'GrupoConceitoList',
        meta: { title: "Grupo de Conceitos" },
        component: () => import('@pages/GrupoConceito/GrupoConceito')
    },
    {
        path: 'grupo-conceito/create',
        name: 'GrupoConceitoCreate',
        meta: { title: "Cadastrar Grupo de Conceitos" },
        component: () => import('@pages/GrupoConceito/GrupoConceitoCreate')
    },
    {
        path: 'grupo-conceito/show',
        name: 'GrupoConceitoShow',
        meta: { title: "Visualizar Grupo de Conceitos" },
        component: () => import('@pages/GrupoConceito/GrupoConceitoShow')
    },
    {
        path: 'grupo-conceito/edit',
        name: 'GrupoConceitoEdit',
        meta: { title: "Editar Grupo de Conceitos" },
        component: () => import('@pages/GrupoConceito/GrupoConceitoEdit')
    },
    {
        path: 'grupo-conceito/avaliar/create',
        name: 'AvaliarCreate',
        meta: { title: "Cadastrar Item à Avaliar" },
        component: () => import('@pages/GrupoConceito/AvaliarCreate')
    },
    {
        path: 'grupo-conceito/conceito/create',
        name: 'ConceitoCreate',
        meta: { title: "Cadastrar Conceito" },
        component: () => import('@pages/GrupoConceito/ConceitoCreate')
    },
    {
        path: 'modelo-avaliacao',
        name: 'ModeloAvaliacaoList',
        meta: { title: "Modelo de Avaliação" },
        component: () => import('@pages/ModeloAvaliacao/ModeloAvaliacao')
    },
    {
        path: 'modelo-avaliacao/create',
        name: 'ModeloAvaliacaoCreate',
        meta: { title: "Cadastrar Modelo de Avaliação" },
        component: () => import('@pages/ModeloAvaliacao/ModeloAvaliacaoCreate')
    },
    {
        path: 'modelo-avaliacao/edit/:id',
        props: true,
        name: 'ModeloAvaliacaoEdit',
        meta: { title: "Editar Modelo de Avaliação" },
        component: () => import('@pages/ModeloAvaliacao/ModeloAvaliacaoEdit')
    },
    {
        path: 'modelo-avaliacao/show/:id',
        props: true,
        name: 'ModeloAvaliacaoShow',
        meta: { title: "Visualizar Modelo de Avaliação" },
        component: () => import('@pages/ModeloAvaliacao/ModeloAvaliacaoShow')
    },
    {
        path: 'periodo-avaliacao/create/:modelo_avaliacao_id',
        props: true,
        name: 'PeriodoAvaliacaoCreate',
        meta: { title: "Cadastrar Período de Avaliação" },
        component: () => import('@pages/ModeloAvaliacao/PeriodoAvaliacaoCreate')
    },
    {
        path: 'periodo-avaliacao/edit/:id',
        props: true,
        name: 'PeriodoAvaliacaoEdit',
        meta: { title: "Editar Período de Avaliação" },
        component: () => import('@pages/ModeloAvaliacao/PeriodoAvaliacaoEdit')
    },
    {
        path: 'avaliacao/create/:periodo_avaliacao_id',
        props: true,
        name: 'AvaliacaoCreate',
        meta: { title: "Cadastrar Avaliação" },
        component: () => import('@pages/ModeloAvaliacao/AvaliacaoCreate')
    },
    {
        path: 'avaliacao/edit/:id',
        props: true,
        name: 'AvaliacaoEdit',
        meta: { title: "Editar Avaliação" },
        component: () => import('@pages/ModeloAvaliacao/AvaliacaoEdit')
    },
    {
        path: 'nota',
        name: 'NotaList',
        meta: { title: "Notas", permissions: ['nota_visualizar'] },
        component: () => import('@pages/Nota/Nota')
    },
    {
        path: 'nota/disciplina/:id',
        props: true,
        name: 'NotaDisciplina',
        meta: { title: "Lançamento de Notas por Disciplina" , permissions: ['nota_visualizar']},
        component: () => import('@pages/Nota/NotaDisciplina')
    },
    {
        path: 'nota/disciplina/create/:turma_id/:disciplina_id',
        props: true,
        name: 'NotaDisciplinaCreate',
        meta: { title: "Lançamento de Notas por Disciplina", permissions: ['nota_inserir'] },
        component: () => import('@pages/Nota/NotaDisciplinaCreate')
    },
    {
        path: 'nota/disciplina/show/:id',
        props: true,
        name: 'NotaDisciplinaShow',
        meta: { title: "Visualização de Lançamento de Notas por Disciplina", permissions: ['nota_visualizar'] },
        component: () => import('@pages/Nota/NotaDisciplinaShow')
    },
    {
        path: 'nota/aluno/:id',
        props: true,
        name: 'NotaAluno',
        meta: { title: "Lançamento de Nota por Aluno", permissions: ['nota_visualizar'] },
        component: () => import('@pages/Nota/NotaAluno')
    },
    {
        path: 'nota/aluno/create/:matricula_id',
        props: true,
        name: 'NotaAlunoCreate',
        meta: { title: "Lançamento de Notas por Aluno", permissions: ['nota_inserir'] },
        component: () => import('@pages/Nota/NotaAlunoCreate')
    },
    {
        path: 'frequencia',
        name: 'FrequenciaList',
        meta: { title: "Frequência", permissions: ['frequencia_visualizar'] },
        component: () => import('@pages/Frequencia/FrequenciaList')
    },
    {
        path: 'frequencia/editar/:matricula_id',
        props: true,
        name: 'FrequenciaEdit',
        meta: { title: "Cadastrar/Editar Frequência", permissions: ['frequencia_editar'] },
        component: () => import('@pages/Frequencia/FrequenciaEdit')
    },
    {
        path: 'frequencia/justificar',
        props: true,
        name: 'FrequenciaJustificar',
        meta: { title: "Justificar Faltas" , permissions: ['frequencia_editar']},
        component: () => import('@pages/Frequencia/FrequenciaJustificar')
    },


]
